/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'Navigation',
        import: () => import('@stories/Widgets/Navigation/SiteNavigation/SiteNavigation'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Footers/SiteFooter/SiteFooter'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PageBanner',
        import: () => import('@stories/Widgets/PageBanner/PageBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Carousel',
        import: () => import('@stories/Widgets/Carousel/Carousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ArticleContent',
        import: () => import('@stories/Widgets/Content/ArticleContent/ArticleContent'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'BackButton',
        import: () => import('@stories/Widgets/Content/BackButton/BackButton'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ContentSnippet',
        import: () => import('@stories/Widgets/Content/ContentSnippet/ContentSnippet'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'GridRows',
        import: () => import('@stories/Widgets/Content/GridRows/GridRows'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductTop',
        import: () => import('@stories/Widgets/Content/ProductTop/ProductTop'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RecipeContent',
        import: () => import('@stories/Widgets/Content/RecipeContent/RecipeContent'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/Content/RichText/RichText'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SearchResults',
        import: () => import('@stories/Widgets/Content/SearchResults/SearchResults'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SectorGrid',
        import: () => import('@stories/Widgets/Content/SectorGrid/SectorGrid'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ShareButtons',
        import: () => import('@stories/Widgets/Content/ShareButtons/ShareButtons'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TableHolder',
        import: () => import('@stories/Widgets/Content/TableHolder/TableHolder'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TitleBlock',
        import: () => import('@stories/Widgets/Content/TitleBlock/TitleBlock'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'CTA',
        import: () => import('@stories/Widgets/CTA/GeneralCTA/CTA'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SectorsCTA',
        import: () => import('@stories/Widgets/CTA/SectorsCTA/SectorsCTA'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'DownloadCTA',
        import: () => import('@stories/Widgets/DownloadCTA/DownloadCTA'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'Header',
        import: () => import('@stories/Widgets/Header/Header'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'HomeCarousel',
        import: () => import('@stories/Widgets/HomeCarousel/HomeCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ArticleListing',
        import: () => import('@stories/Widgets/Listings/ArticleListing/ArticleListing'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'ProductListing',
        import: () => import('@stories/Widgets/Listings/ProductListing/ProductListing'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RecipeListing',
        import: () => import('@stories/Widgets/Listings/RecipeListing/RecipeListing'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'SupportListing',
        import: () => import('@stories/Widgets/Listings/SupportListing/SupportListing'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'NewsletterSignup',
        import: () => import('@stories/Widgets/NewsletterSignup/NewsletterSignup'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'PageBanner',
        import: () => import('@stories/Widgets/PageBanner/PageBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TopBar',
        import: () => import('@stories/Widgets/TopBar/TopBar'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'VideoModel',
        import: () => import('@stories/Widgets/VideoModel/VideoModel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'VideoPlayer',
        import: () => import('@stories/Widgets/VideoPlayer/VideoPlayer'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'FormHolder',
        import: () => import('@stories/Widgets/FormHolder/FormHolder'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'TwoThirdSnippet',
        import: () => import('@stories/Widgets/Content/TwoThirdSnippet/TwoThirdSnippet'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'GridCarousel',
        import: () => import('@stories/Widgets/Content/GridCarousel/GridCarousel'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'StandardContent',
        import: () => import('@stories/Widgets/Content/StandardContent/StandardContent'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
    {
        name: 'RecipeBanner',
        import: () => import('@stories/Widgets/RecipeBanner/RecipeBanner'),
        config: {
            hydrate: true,
            hydrateBehaviour: 'in-view',
        },
    },
];
